<template>
  <div class="class">
    <div class="loading-wrapper" v-show="showLoading">
      <Loading></Loading>
    </div>
    <div class="top">
      <p class="apply" style="font-size: 14px">共<span class="num" :v-model="total"> {{total}} </span>个培训班</p>
      <el-input class="inp" v-model="listQuery.className"></el-input>
      <el-button class="el-but" type="primary" @click="getAllClassByMoHu()" >搜索</el-button>
    </div>
    <!-- 排序方式 -->
    <!-- <div style="display: flex;align-items: center;justify-content: space-around;font-size: 14px;">
      <div></div>
      <div id="" @click="opentime(!registerOpenTime)" style="padding: 10px;">
        开班时间
      </div>
      <div id="" @click="openmun(!registerOpenTime)">
        报名人数
      </div>
    </div> -->
    <div v-if="dataList.length>0">
    <div class="hig" style="margin: 0;padding: 0" v-for="(i,k) in dataList" :key="k">
      <div class="left">
        <div class="left-title">
          <h4 style="width: 450px; text-align: left" :v-model="school.className">
            {{i.className}}
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.id }}</span>
            </template>
          </h4>
          <p class="but" style="font-size: 12px;">{{applyTime(i,1)}}</p>
          <p v-if="applyTime(i,1) === '报名中'" style="font-size: 12px; margin-left: 5px; margin-top: 15px; color: red">截止时间:{{i.timeStr}}</p>
        </div>
        <div class="left-cont">
          <span style="font-size: 12px; opacity: 0.7">欢迎您参加
          {{i.className}}！祝您顺利完成毕业,愉快度过培训的每一天！</span>
        </div>
        <div class="left-tag">
          <p style="font-size: 10px">提供单位:<span style="font-size: 12px;margin-left: 5px">{{i.sponsor}}</span>
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.duty }}</span>
            </template>
          </p><p style="font-size: 10px">发布时间:<span style="font-size: 12px;margin-left: 5px">{{i.registerOpenTime}}</span></p>
        </div>
      </div>
      <div class="right">
        <el-button class="e-but" @click="openDialog(i.classId)"
                   :type="applyTime(i,0)!=='立即报名'?'info':'primary'"
                   :disabled="applyTime(i,0)!=='立即报名' || isDisabled"
                   v-if="i.status !== 1">{{applyTime(i,0)}}
        </el-button>
        <el-button class="e-but" type="primary" v-if="i.status === 1" disabled>已报名
        </el-button>
        <!-- <el-button class="e-but" @click="openDialog(i.classId)"
                   :type="applyTime(i,0)!=='立即报名'?'info':'primary'"
                   :disabled="applyTime(i,0)!=='立即报名' || isDisabled">{{applyTime(i,0)}}
        </el-button> -->
        <p class="right-text" style="font-size: 12px">报名:{{i.applyNumber}}人</p>
      </div>
    </div>
    </div>
    <div v-else style="padding: 20px; color: #999;">没有数据</div>
    <div >
      <div style="display: flex;align-items: center;position: absolute;left: 10px;margin-top: 9px;">
        <p style="font-size: 14px">排序：</p>
        <el-form :model="opentest" ref="opentest">
          <el-button @click="opentime(!registerOpenTime)"  type="primary">开班时间</el-button>
        </el-form>
        <el-form :model="opentestone" ref="opentestone" style="margin-left: 10px;">
          <el-button @click="openmun(!registerOpenTime)" type="warning">报名人数</el-button>
        </el-form>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listQuery.pageNum"
        :page-size="listQuery.pageSize"
        layout="total,sizes,  prev, pager, next, jumper"
        :total="total"
        class="pag"
      >
      </el-pagination>
    </div>
    <el-dialog :title="titlee" :visible.sync="applyts" class="dia">
        <i class="el-icon-edit" style="position: relative; top: -58px; left: -70px; font-size: 16px;"></i>
        <el-form
          :model="school"
          label-position="right"
          label-width="120px"
          :rules="rules1"
          ref="school">
              <el-form-item label="姓名" prop="stuName">
                <el-input v-model="school.stuName" autocomplete="off" style="width: 90%" ></el-input>
              </el-form-item>
              <el-form-item label="性别" prop="sex">
                <el-select v-model="school.sex" style="width: 90%" >
                  <el-option value="0" label="男"></el-option>
                  <el-option value="1" label="女"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="干部层级" prop="stuLeadDuty">
                 <el-select v-model="school.stuLeadDuty" style="width: 90%" >
                    <el-option v-for="(i) in dictionaryList4" :key="i.value" :label="i.value" :value="i.value"></el-option>
                 </el-select>
              </el-form-item>
              <el-form-item label="单位名称" prop="depName">
                  <el-cascader
                    :change-on-select="true"
                    :props="defaultParamsOne"
                    :options="optionsOne"
                    :clearable="true"
                    :show-all-levels="false"
                    v-model="school.depName"
                    filterable
                    style="width: 90%" ></el-cascader>
              </el-form-item>
              <el-form-item label="职务" prop="stuDuty">
                <el-input  v-model="school.stuDuty" style="width: 90%" placeholder="请输入职务"></el-input>
              </el-form-item>
              <el-form-item label="出生日期" prop="stuBirth">
                <el-date-picker
                  v-model="school.stuBirth"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择日期"
                  style="width: 90%" >
                </el-date-picker>
              </el-form-item>
              <!-- <el-form-item label="联系电话" prop="stuPhone">
                <el-input  v-model="school.stuPhone" style="width: 90%" autocomplete="off" maxlength="11"></el-input>
              </el-form-item> -->
              <el-form-item label="职级" prop="duty">
                  <el-cascader
                    :change-on-select="true"
                    :props="defaultParams"
                    :options="optionsTwo"
                    :clearable="true"
                    :show-all-levels="false"
                    v-model="school.duty"
                    filterable
                     style="width: 90%" ></el-cascader>
              </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="applytser()">取 消</el-button>
          <el-button type="primary" @click="Apply('school')" :disabled="isDisable">提 交</el-button>
        </div>
      </el-dialog>
  </div>
</template>

<script>
  import { getAllClassByMoHus, GetAllById, Apply, GetList, showTreesByRedis, showTreeByRedis } from '@/api/user.js'
  import Loading from '@/components/loading/Loading.vue'
  import { mapMutations } from 'vuex' // 导入方法
  export default {
    name: 'Home',
    components: {
      Loading
    },
    data () { // 数据模型
      // const checkstuName = (rule, value, callback) => { // 名字
      //   if (value === '') {
      //     callback(new Error('不能为空'))
      //   } else {
      //     callback()
      //   }
      // }
      // const checkstuBirth = (rule, value, callback) => { // 出生日期
      //   if (value === '') {
      //     callback(new Error('不能为空'))
      //   } else {
      //     callback()
      //   }
      // }
      // const checksex = (rule, value, callback) => { // 性别
      //   if (value === '') {
      //     callback(new Error('不能为空'))
      //   } else {
      //     callback()
      //   }
      // }
      // const checkdapartmentld = (rule, value, callback) => { // 单位名称
      //   if (value === '') {
      //     callback(new Error('不能为空'))
      //   } else {
      //     callback()
      //   }
      // }
      // const checkstuDuty = (rule, value, callback) => { // 职务
      //   if (value === '') {
      //     callback(new Error('不能为空'))
      //   } else {
      //     callback()
      //   }
      // }
      // const checkstuPhone = (rule, value, callback) => { // 联系电话
      //    if (value === '') {
      //     callback(new Error('不能为空'))
      //   } else {
      //     callback()
      //   }
      // }
      return {
        optionsOne: [],
        defaultParamsOne: {
          label: 'label',
          value: 'label',
          children: 'children'
        },
        optionsTwo: [],
        defaultParams: {
          label: 'label',
          value: 'label',
          children: 'children'
        },
        isDisabled: false,
        d: '',
        h: '',
        m: '',
        s: '',
        endDate: [],
        selectDutyList: [],
        timeOut: null,
        tiemHer: 0,
        timeDay: 0,
        timeMin: 0,
        timer: '',
        currentTime: new Date(),
        showLoading: false,
        dictionaryList4: [],
        applyShow: false,
        school: {
          classId: '',
          id: '',
          stuName: '',
          stuBirth: '',
          sex: '',
          depName: '',
          stuDuty: '',
          duty: [],
          stuPhone: '',
          registerEndTime: '',
          userNameToken: ''
        },
        rules1: {
          stuName: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
          sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
          stuLeadDuty: [{ required: true, message: '请选择干部层级', trigger: 'change' }],
          depName: [{ required: true, message: '请选择单位名称', trigger: 'change' }],
          duty: [{ required: true, message: '请填写职级', trigger: 'change' }],
          stuBirth: [{ required: true, message: '请选择出生日期', trigger: 'change' }],
          // stuPhone: [{ required: true, message: '请填写联系电话', trigger: 'blur' }],
          stuDuty: [{ required: true, message: '请选择职务', trigger: 'blur' }]
        },
        from: '',
        orderby: '',
        opentest: {
          trainNumber: ''
        },
        opentestone: {
          registerOpenTime: ''
        },
        listQuery: {
          pageNum: 1,
          pageSize: 10
        },
        registerOpenTime: false,
        total: 0,
        pageNum: 1,
        pageSize: 10,
        dataList: [
          {
            classId: '',
            id: '',
            className: '',
            applyNumber: '',
            registerOpenTime: '',
            registerEndTime: '',
            sponsor: '',
            trainNumber: '',
            timeStr: ''
          }
        ],
        titlee: '填写报名信息',
        applyts: false,
        formLabelwidth: '100px',
        formLabeleft: '100px',
        isDisable: false
      }
    },
    mounted() { // 钩子函数
      this.getAllClassByMoHu()// 初始化所有发布培训班信息
      // this.GetAllById(170) // 部门名称
      // this.GetAllById(20200) // 职务
     this.GetAllById(300) // 干部层级
     this.showTreesByRedis() // 从缓存中职级
     this.showTreeByRedis() // 从缓存单位名称
    },
    computed: {}, // 计算属性
    methods: { // 定义方法
      ...mapMutations({
        saveUser: 'SAVE_USER'
      }),
      // 获取单位名称列表数据
      showTreeByRedis () {
        showTreeByRedis().then(resp => {
          console.log(resp.data)
          if (resp.data.code === '200') {
            this.optionsOne = resp.data.data.dictionaryTrees
          }
        })
      },
       // 职级缓存
      showTreesByRedis() {
        showTreesByRedis().then(resp => {
          if (resp.data.code === '200') {
            // 将获得到的数据赋值给options
            this.optionsTwo = resp.data.data.dictionaryTrees
          }
        })
      },
      // 字典表
      GetAllById (id) {
        GetAllById(id).then(resp => {
          if (resp.data.code === '0') {
              if (id === 300) {
                this.dictionaryList4 = resp.data.data
              }
          }
        })
      },
      countTime: function() {
        setInterval(() => {
          this.dataList.forEach(element => {
            const endDate = element.registerEndTime
            // 获取当前时间
            const date = new Date()
            const now = date.getTime()
            // 设置截止时间
            const end = new Date(endDate).getTime()
            // 时间差
            const leftTime = end - now

            // 定义变量 d,h,m,s保存倒计时的时间
            if (leftTime >= 0) {
              const d = Math.floor(leftTime / 1000 / 60 / 60 / 24)
              const h = Math.floor((leftTime / 1000 / 60 / 60) % 24)
              const m = Math.floor((leftTime / 1000 / 60) % 60)
              const s = Math.floor((leftTime / 1000) % 60)

              const timeStr = d + '天' + h + '小时' + m + '分钟' + s + '秒'
              this.$set(element, 'timeStr', timeStr)
            }
          })
        }, 1000)
      },
      // 个人信息回显
      openDialog (classId) {
        this.applyts = true
        const classid = this.school.classId
        console.log(classid)
        const usernam = this.$store.getters.username
        GetList(usernam).then(resp => {
          this[classId] = { resp }
          if (resp.data.code === '200') {
            this.school = resp.data.data[0] // 接收返回的数据
            this.school.classId = classId
            this.school.depName = this.school.depName.split(',')
            // this.school.stuDuty = this.school.stuDuty.split(',')
            this.school.duty = this.school.duty.split(',')
          }
        })
      },
      handleSizeChange(e) {
        this.listQuery.pageSize = e
        this.getAllClassByMoHu()
      },
      handleCurrentChange(e) {
        this.listQuery.pageNum = e
        this.getAllClassByMoHu()
      },
      getAllClassByMoHu: function () {
        getAllClassByMoHus(this.listQuery).then(resp => {
          console.log(resp)
          if (resp.data.code === '200') {
            this.total = resp.data.data.total // 查询总条数
            this.dataList = resp.data.data.rows // 接收返回的数据
             if (this.dataList.length > 0) {
              this.dataList.forEach(element => {
                const endDate = element.registerEndTime
                // 获取当前时间
                const date = new Date()
                const now = date.getTime()
                // 设置截止时间
                const end = new Date(endDate).getTime()
                // 时间差
                const leftTime = end - now

                // 定义变量 d,h,m,s保存倒计时的时间
                if (leftTime >= 0) {
                  const d = Math.floor(leftTime / 1000 / 60 / 60 / 24)
                  const h = Math.floor((leftTime / 1000 / 60 / 60) % 24)
                  const m = Math.floor((leftTime / 1000 / 60) % 60)
                  const s = Math.floor((leftTime / 1000) % 60)

                  const timeStr = d + '天' + h + '小时' + m + '分钟' + s + '秒'
                  this.$set(element, 'timeStr', timeStr)
                }
              })
              this.countTime()
            }
          }
        })
      },
      out() {
        this.centerDialogVisible = false
      },
      Apply (school) {
        this.$refs[school].validate(valid => {
          if (valid) {
            if (this.school.sex === '男') {
                  this.school.sex = 0
                } else if (this.school.sex === '女') {
                  this.school.sex = 1
                }
                this.isDisable = true // 使按钮不可用
                setTimeout(() => {
                      this.isDisable = false // 事件执行结束，恢复
                }, 7000) // 计时器：三秒后才能再次点击
                const student = {
                  id: this.school.id,
                  classId: this.school.classId,
                  stuName: this.school.stuName,
                  stuBirth: this.school.stuBirth,
                  sex: this.school.sex,
                  depName: this.school.depName + '',
                  // stuDuty: this.school.stuDuty + '',
                  stuDuty: this.school.stuDuty,
                  stuPhone: this.$store.getters.username,
                  // stuPhone: this.school.stuPhone,
                  stuLeadDuty: this.school.stuLeadDuty,
                  duty: this.school.duty + '',
                  userNameToken: this.$store.getters.username
                }// 提交按钮
                console.log(student)
                Apply(student).then(resp => {
                  // this[school] = {}
                  if (resp.data.code === '0') {
                    this.$notify({
                      title: '成功',
                      message: '恭喜，报名成功。',
                      type: 'success'
                    })
                    // this.isDisabled = true
                    this.applyts = false
                    this.getAllClassByMoHu()
                  } else if (resp.data.code === '406') {
                    this.$notify({
                      title: '警告',
                      message: '请勿重复报名',
                      type: 'error'
                    })
                    this.applyts = false
                  }
                })
          }
        })
      },
      applytser() {
        this.applyts = false
      },
      opentime(registerOpenTime) {
        if (registerOpenTime) {
          this.listQuery.registerOpenTime = 'desc'
        } else {
          this.listQuery.registerOpenTime = 'asc'
        }
        this.listQuery.trainNumber = null
        this.registerOpenTime = registerOpenTime
        this.getAllClassByMoHu()
      },
      openmun(registerOpenTime) {
        if (registerOpenTime) {
          this.listQuery.trainNumber = 'desc'
        } else {
          this.listQuery.trainNumber = 'asc'
        }
        this.listQuery.registerOpenTime = null
        this.registerOpenTime = registerOpenTime
        this.getAllClassByMoHu()
      },
      applyTime(row, type) {
        const registerOpenTime = row.registerOpenTime
        // 开班时间
        const openTime = new Date(registerOpenTime).getTime()
        // 现在时间
        const currTime = new Date().getTime()
        // 结束时间
        const endTime = new Date(row.registerEndTime).getTime()
        // 报名人数
        const applyNumber = row.applyNumber
        // 开班人数
        const trainNumber = row.trainNumber
        if (openTime < currTime && currTime < endTime) {
          // 正常 需要判断人数
          if (applyNumber >= trainNumber) {
            return '人数已满'
          }
          return type === 0 ? '立即报名' : '报名中'
        }
        if (openTime > currTime) {
           // 报名未开始
          return '未开始'
        }
        if (endTime < currTime) {
          // 报名已结束
          return '已结束'
        }
        // console.log(openTime > currTime)
        return type === 0 ? '立即报名' : '报名中'
      },
      querySearch(queryString, cb) {
        const restaurants = this.selectDutyList
        const results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
        // 调用 callback 返回建议列表的数据
        cb(results)
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        }
      },
      handleSelect(item) {
        console.log(item)
      }
    },
    created() {
    }
  }
</script>

<style>
.el-dialog{box-shadow:none}
  .class{
    width: 100%;
    background-color: white;
    position: relative;
    margin-top: -10px;
  }
  .top{
    width: 100%;
    height: 60px;
    background-color: #e9e9e9;
    display: flex;
    align-items: center;
  }
  .num{
    color: #2c2fd6;
  }
  .apply{
    position: absolute;
    left: 30px;
    margin: 0;
  }
  .inp{
    width: 250px;
    position: absolute;
    left: 180px;
    border: none;
  }
  .el-but{
    position: absolute;
    left: 440px;
  }
  .e-p{
    position: absolute;
    left: 720px;
    margin: 0;
  }
  .hig{
    width: 96%;
    border-bottom: 1px solid  #ececec;
    padding: 10px 20px !important;
    display: flex;
  }
  .calss:last-child .hig{
    margin-bottom: 45px;
  }
  .left{
    height: auto;
    flex: 8;
  }
  .left-title{
    display: flex;
  }
  .left-cont{
    display: flex;
    justify-content: flex-start;
  }
  .left-tag{
    display: flex;
    justify-content: flex-start;
  }
  .left-tag p{
    margin-right: 30px;
  }
  .right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 2;
  }
  .right-text{}
  .but{
    background: #ffc439;
    color: #fff;
    width: 60px;
    height: 20px;
    line-height: 20px;
    padding: 2px;
    border-radius: 2px;
  }
  .e-but{
    left: 85%;
  }
  .butt{background: #cccccc;
    color: #fff;
    width: 60px;
    height: 20px;
    line-height: 20px;
    padding: 2px;
    border-radius: 2px;
  }
  .t{position: absolute; top: -301px;opacity: 0}
  .e-op{position: absolute; top: -400px;opacity: 0}
  .pag{position: absolute;right:10px; margin-top: 20px;}
  .big-fa{display: flex}
  .big{display: flex; align-items: center; justify-content: center;margin: 0 auto}
  .big div{flex: 1}
  .timeone{position: relative;left: 760px;width: 80px;}
  .numtwo{position: relative;left: 760px;width: 80px; margin-left: 10px}
</style>
